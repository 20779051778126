import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PictureType } from '../../models/picture-type.enum';

@Component({
    selector: 'gwg-identification-backside',
    templateUrl: './identification-backside.component.html',
    styleUrls: ['./identification-backside.component.scss'],
})
export class IdentificationBacksideComponent {

    type: PictureType = PictureType.Backside;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) { }

    navigateToVerification(): void {
        this.router.navigate([{ outlets: { tab2: 'verification' } }], { relativeTo: this.route });
    }

}
