import { LayoutModule } from '@abcfinlab/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { IdentificationUploadFileModule } from '../identification-upload-file/identification-upload-file.module';
import { IdentificationModule } from '../identification/identification.module';
import { IdentificationContainerRoutingModule } from './identification-container-routing.module';
import { IdentificationContainerComponent } from './identification-container/identification-container.component';

@NgModule({
    declarations: [IdentificationContainerComponent],
    imports: [
        CommonModule,
        IdentificationContainerRoutingModule,
        IdentificationModule,
        IdentificationUploadFileModule,
        MatTabsModule,
        MatIconModule,
        FlexLayoutModule,
        LayoutModule,
    ],
})
export class IdentificationContainerModule { }
