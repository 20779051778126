import { Component } from '@angular/core';
import { PictureType } from '../../models/picture-type.enum';

@Component({
    selector: 'gwg-upload-file-frontside',
    templateUrl: './upload-file-frontside.component.html',
    styleUrls: ['./upload-file-frontside.component.css'],
})
export class UploadFileFrontsideComponent {

    side: PictureType = PictureType.Frontside;

    text: string = 'vorderseite';

    constructor() { }

}
