import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { DefaultTypeGWGStrategy, HirePurchaseTypeGWGStrategy } from '../../../../../../apps/shell/src/app/models/classes/ContractStrategies.class';
import { ContractTypeId } from '../../../../../../apps/shell/src/app/models/enums/ContractTypeId.enum';
import { GWGProcessDataDTO } from '../../../../../../apps/shell/src/app/models/GWGProcessDataDTO.interface';
import { QuoteState } from '../../../../../../apps/shell/src/app/state/Quote.state';
import { LeasingQuoteIdService } from '../services/leasing-quote-id.service';
import { QuoteService } from '../services/quote.service';
import { AppConfig } from '@abcfinlab/core';
import { LeasingQuoteDTO } from 'apps/shell/src/app/models/LeasingQuoteDTO.interface';

@Injectable({
    providedIn: 'root',
})
export class GwgInitializationResolver implements Resolve<object> {

    constructor(
        private readonly http: HttpClient,
        private readonly leasingQuoteIdService: LeasingQuoteIdService,
        private readonly quoteService: QuoteService,
        private readonly store: Store,
        private readonly appConfig: AppConfig,
    ) {
    }

    resolve(_route: ActivatedRouteSnapshot): Observable<unknown> {
        const selectedQuote: LeasingQuoteDTO = this.store.selectSnapshot(QuoteState.getQuote);
        const gwgProcessData: GWGProcessDataDTO = {
            quote_id: selectedQuote.quote_id,
            contract_type: selectedQuote.quote_calculation.contract_type,
            lesseeId: selectedQuote.lessee_id,
        };
        this.leasingQuoteIdService.setLeasingQuoteDataOnce(gwgProcessData);
        if (gwgProcessData.contract_type === ContractTypeId.MIETKAUF || gwgProcessData.contract_type === ContractTypeId.MKN) {
            this.quoteService.setContractTypeStrategy(new HirePurchaseTypeGWGStrategy(this.appConfig));
        } else {
            this.quoteService.setContractTypeStrategy(new DefaultTypeGWGStrategy(this.appConfig));
        }
        return of(true);
    }

}
