import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGWGStrategyInterface } from '../../../../../../apps/shell/src/app/models/interfaces/IGWGStrategy.interface';
import { LeasingQuoteDTO } from '../../../../../../apps/shell/src/app/models/LeasingQuoteDTO.interface';
import { RemoteQuoteDTO } from '../../../../../../apps/shell/src/app/models/RemoteQuoteDTO.interface';
import { AppConfig } from '@abcfinlab/core';

@Injectable({
    providedIn: 'root',
})
export class QuoteService {

    private readonly _HOST: string;
    private _contractTypeStrategy: IGWGStrategyInterface = null;

    constructor(
        private readonly _http: HttpClient,
        private readonly appConfig: AppConfig,
    ) {
        this._HOST = appConfig.get('host');
    }

    /**
   * Set the service contract strategy to deal with different endpoints
   * @param strategy  The contract type strategy to be used through GWG process because of different endpoints since *Mietkauf*
   */
    public setContractTypeStrategy(strategy: any): void {
        this._contractTypeStrategy = strategy;
    }

    /**
   * Get a specific quote based on a QuoteUUID
   * @param quoteUUID          The UUID of the quote
   */
    public getQuoteByUUID(quoteUUID: string): Observable<LeasingQuoteDTO> {
        return this._http.get<LeasingQuoteDTO>(`${this._contractTypeStrategy.baseURL()}/${quoteUUID}`, { withCredentials: true });
    }

    /**
   * Get a specific quote based on a SigningLinkUUID
   * @param signingLinkUUID     The UUID of the signing-link to a given quote on remote process
   */
    public getQuoteBySigningLink(signingLinkUUID: string): Observable<RemoteQuoteDTO> {
        return this._http.get<RemoteQuoteDTO>(`${this._HOST}/api/v1/remote/${signingLinkUUID}/quote`);
    }

}
