import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MoneyLaunderingIdentificationDTO } from '../models/money-laundering-identification-dto';
import { LeasingQuoteIdService } from './leasing-quote-id.service';
import { AppConfig } from '@abcfinlab/core';

@Injectable({
    providedIn: 'root',
})
export class MoneyLaunderingIdentificationService {

    private readonly MONEY_LAUNDERING_IDENTIFICATION_PATH: string;
    private readonly hostUrl: string;

    constructor(
        private readonly http: HttpClient,
        private readonly leasingQuoteIdService: LeasingQuoteIdService,
        private readonly appConfig: AppConfig,
    ) {
        this.hostUrl = appConfig.get('host');
        this.MONEY_LAUNDERING_IDENTIFICATION_PATH = `${this.hostUrl}/api/v1/gwg` + '/identification';
    }

    getMoneyLaunderingIdentification(): Observable<MoneyLaunderingIdentificationDTO> {
        const leasingQuoteId = this.leasingQuoteIdService.getLeasingQuoteId();
        return this.http.get<MoneyLaunderingIdentificationDTO>(
            `${this.MONEY_LAUNDERING_IDENTIFICATION_PATH}/${leasingQuoteId}`,
            { withCredentials: true },
        );
    }

}
